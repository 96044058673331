import { useEffect, useMemo, useState } from "react";
import { LocalArea } from "../config/LocalArea";
import { getAppUrls, getQrImageUrl } from "../config/constants";
import { getWindow } from "@utils/window";

export type AppUrls = {
  ios: string;
  android: string;
  common: string;
  qr: {
    ios: string;
    android: string;
  };
};

export const useAppUrls = ({ area }: { area?: LocalArea }): AppUrls => {
  const [from] = useUrlQuery("from");
  return useMemo(() => {
    const trackingId = area?.trackingId || from || "lp_button";
    const base = getAppUrls(trackingId);

    const qr = {
      ios: getQrImageUrl(base.ios),
      android: getQrImageUrl(base.android),
    };

    return {
      ...base,
      qr,
    };
  }, [area, from]);
};

export const useUrlQuery = (...keys: string[]): (string | null)[] => {
  const [values, setValues] = useState<(string | null)[]>(keys.map(() => null));
  const locationSearch = getWindow((w) => w.location.search);
  useEffect(() => {
    const params = new URLSearchParams(locationSearch || "");
    const newValues = keys.map((key) => params.get(key));
    setValues(newValues);
  }, []);

  return values;

  // MEMO: Gatsby では、属性（a.href）の変更が初回描画時に動的に行われても、反映されない問題がある。
  // これを回避するため、 window.location.search を用いる場合、 useEffect と state を使って、
  // SSR時とCSR時にstateを変更させないといけない。
  // See also: https://stackoverflow.com/questions/56398811/unable-to-update-jsx-attribute-based-on-url-parameter-in-a-gatsby-app-running-in/56403717#56403717
};
