import * as React from "react";
import DynamicLinkWraningLayout from "@layouts/DynamicLinkWraningLayout";

// アプリを開くURLだけれどもアプリインストールをしていないときに
// ブラウザで開かれてしまうので、そのときはこのページを表示します。

// https://app.wanpass.me/map?facilityId=aaa のようなURLが開かれたとき、
// このページリダイレクトします。

const Page = () => {
  return <DynamicLinkWraningLayout />;
};

export default Page;
