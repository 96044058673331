import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppUrls } from "../hooks/urlHooks";

type Props = {
  qr?: boolean;
};

const AppBanners = ({ qr }: Props) => {
  const appUrls = useAppUrls({});
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <BannerCol href={appUrls.ios}>
          <IosImage />
          {qr && (
            <div style={{ textAlign: "center", marginTop: 8 }}>
              <img
                src={appUrls.qr.ios}
                alt="App Store からダウンロード"
                width={130}
              />
            </div>
          )}
        </BannerCol>
        <BannerCol href={appUrls.android}>
          <AndroidImage />
          {qr && (
            <div style={{ textAlign: "center", marginTop: 8 }}>
              <img
                src={appUrls.qr.android}
                alt="Google Play からダウンロード"
                width={130}
              />
            </div>
          )}
        </BannerCol>
      </Row>
    </Container>
  );
};

export default AppBanners;

const BannerCol = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <Col sm="9" md="6" className="mb-3">
    <a href={href}>{children}</a>
  </Col>
);

const IosImage = () => (
  <StaticImage
    src="../images/button/ios_store_banner.png"
    alt="App Store からダウンロード"
  />
);

const AndroidImage = () => (
  <StaticImage
    src="../images/button/android_store_banner.png"
    alt="Google Play で手に入れよう"
  />
);
